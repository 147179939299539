import { lazy } from 'react';
import { Paths } from '@/common/const';
import { ForgotPassword } from '@/components/Auth/ForgotPassword/ForgotPassword';
import { ResetPassword } from '@/components/Auth/ForgotPassword/ResetPassword';
import { AuthLayout } from '@/components/layout/layouts/AuthLayout';
import { DashboardLayout } from '@/components/layout/layouts/DashboardLayout';
import { DashboardUnAuthLayout } from '@/components/layout/layouts/DashboardUnAuthLayout';
import { AdminRoute } from '@/components/routes/Admin/Admin';
import { ClientVersionsRoute } from '@/components/routes/Admin/ClientVersions/ClientVersions';
import { AdminGaitSessionsRoute } from '@/components/routes/Admin/Gait/AdminGaitSessionsRoute';
import { AdminInsightsRoute } from '@/components/routes/Admin/Insights/Insights';
import { AdminLocationsRoute } from '@/components/routes/Admin/Locations/Locations';
import { AdminOrganizationRoute } from '@/components/routes/Admin/Organizations/AdminOrganization';
import { AdminOrganizationsRoute } from '@/components/routes/Admin/Organizations/AdminOrganizations';
import { AdminPROFormsRoute } from '@/components/routes/Admin/PRO/PROForms';
import { AdminPROResponsesRoute } from '@/components/routes/Admin/PRO/PROResponses';
import { AdminScanRoute } from '@/components/routes/Admin/Scan/AdminScanRoute';
import { AdminSettingsRoute } from '@/components/routes/Admin/Settings/Settings';
import { UserByUUID } from '@/components/routes/Admin/Users/UserByUUID';
import { UserSignUpLink } from '@/components/routes/Admin/Users/UserSignUpLink';
import { DashboardRoute } from '@/components/routes/Dashboard/Dashboard';
import { PathsRoute } from '@/components/routes/Demo/Paths';
import { ProductsRoute } from '@/components/routes/Demo/Products';
import { GaitEventPatientsRoute } from '@/components/routes/Gait/EventsPatients';
import { GaitReconcileRoute } from '@/components/routes/Gait/GaitReconcile';
import { GaitReportsRoute } from '@/components/routes/Gait/GaitReports';
import { HEPTemplateRoute } from '@/components/routes/HEPTemplates/HEPTemplate';
import { HEPTemplatesRoute } from '@/components/routes/HEPTemplates/HEPTemplates';
import { ExerciseLibraryRoute } from '@/components/routes/Health/ExerciseLibrary/ExerciseLibrary';
import { Home } from '@/components/routes/Home/Home';
import { NotFoundProviderRoute } from '@/components/routes/NotFound/NotFoundProvider';
import { OrganizationRoute } from '@/components/routes/Organization/Details';
import { PractitionersRoute } from '@/components/routes/Organization/Practitioners';
import { PatientRoute } from '@/components/routes/Patients/Patient';
import { BillingRoute } from '@/components/routes/Patients/PatientBilling/Billing';
import { PatientsRoute } from '@/components/routes/Patients/Patients';
import { ScanSessionsRoute } from '@/components/routes/Scan/Sessions/ScanSessions';
import { AccountInfoRoute } from '@/components/routes/User/AccountInfo';
import { Login } from '@/components/routes/User/Login';
import { SignUp } from '@/components/routes/User/SignUp';

const TelehealthHandsRoute = lazy(
    () => import(/* webpackPrefetch: true */ '@/components/routes/Demo/Telehealth/Hands'),
);
const TelehealthLandingRoute = lazy(
    () => import(/* webpackPrefetch: true */ '@/components/routes/Demo/Telehealth/Landing'),
);
const TelehealthTremorsRoute = lazy(
    () => import(/* webpackPrefetch: true */ '@/components/routes/Demo/Telehealth/Tremors'),
);
const TelehealthExternalShoulderRotationRoute = lazy(
    () => import(/* webpackPrefetch: true */ '@/components/routes/Demo/Telehealth/ExternalShoulderRotation'),
);
export interface RouteDefinition {
    path: string;
    layout: (props: object) => JSX.Element;
    protected?: boolean;
    redirect?: string;
    component?: any;
    routes?: RouteDefinition[];
}

export const routes: RouteDefinition[] = [
    // Common Routes
    {
        path: Paths.userLogin,
        layout: AuthLayout,
        component: Login,
        protected: false,
    },
    {
        path: Paths.userForgotPassword,
        layout: AuthLayout,
        component: ForgotPassword,
        protected: false,
    },
    {
        path: Paths.userResetPassword,
        layout: AuthLayout,
        component: ResetPassword,
        protected: false,
    },
    {
        path: `${Paths.userCreateAccount}:code/`,
        layout: AuthLayout,
        component: SignUp,
        protected: false,
    },
    // Protected Routes
    {
        path: Paths.main,
        layout: DashboardLayout,
        component: Home,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.userAccountInfo}:accountPath/`,
        layout: DashboardLayout,
        component: AccountInfoRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.organizationPatients}:patientRecordId`,
        layout: DashboardLayout,
        component: PatientRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.organizationPatients}:patientRecordId/:patientProduct`,
        layout: DashboardLayout,
        component: PatientRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.organizationPatients}:patientRecordId/:patientProduct/:patientSection`,
        layout: DashboardLayout,
        component: PatientRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.organizationDetails,
        layout: DashboardLayout,
        component: OrganizationRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.organizationPractitioners,
        layout: DashboardLayout,
        component: PractitionersRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.organizationPatients,
        layout: DashboardLayout,
        component: PatientsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    // Unauthorized Dashboard
    {
        path: Paths.userProviderNotFound,
        layout: DashboardUnAuthLayout,
        component: NotFoundProviderRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    // Gait
    {
        path: Paths.gaitReports,
        layout: DashboardLayout,
        component: GaitReportsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.gaitReconcile,
        layout: DashboardLayout,
        component: GaitReconcileRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.gaitEventPatients,
        layout: DashboardLayout,
        component: GaitEventPatientsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    // Scan
    {
        path: Paths.scanSessions,
        layout: DashboardLayout,
        component: ScanSessionsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    // Health
    {
        path: Paths.programsExerciseLibrary,
        layout: DashboardLayout,
        component: ExerciseLibraryRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.programsHEPTemplates,
        layout: DashboardLayout,
        component: HEPTemplatesRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.programsHEPTemplates}:templateId`,
        layout: DashboardLayout,
        component: HEPTemplateRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.billing,
        layout: DashboardLayout,
        component: BillingRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        // path: `${Paths.organizationPatients}:patientRecordId/:patientProduct/:patientSection`,
        path: `${Paths.billing}:billingSection`,
        layout: DashboardLayout,
        component: BillingRoute,
        protected: true,
        redirect: Paths.userLogin,
    },

    // Admin
    {
        path: `${Paths.admin}`,
        layout: DashboardLayout,
        component: AdminRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.adminOrg}`,
        layout: DashboardLayout,
        component: AdminOrganizationsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.adminScanClientVersions}`,
        layout: DashboardLayout,
        component: ClientVersionsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.adminOrg}:organizationId`,
        layout: DashboardLayout,
        component: AdminOrganizationRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.adminPROForms}`,
        layout: DashboardLayout,
        component: AdminPROFormsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.adminPROResponses}`,
        layout: DashboardLayout,
        component: AdminPROResponsesRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.adminLocations}`,
        layout: DashboardLayout,
        component: AdminLocationsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.adminUserByUUID}`,
        layout: DashboardLayout,
        component: UserByUUID,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.adminUserSignUpLink}`,
        layout: DashboardLayout,
        component: UserSignUpLink,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.adminInsights}`,
        layout: DashboardLayout,
        component: AdminInsightsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.adminScanSessions}`,
        layout: DashboardLayout,
        component: AdminScanRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.adminGaitSessions}`,
        layout: DashboardLayout,
        component: AdminGaitSessionsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: `${Paths.adminSettings}`,
        layout: DashboardLayout,
        component: AdminSettingsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.dashboard,
        layout: DashboardLayout,
        component: DashboardRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    // Demo
    {
        path: Paths.paths,
        layout: DashboardLayout,
        component: PathsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.demoHands,
        layout: DashboardLayout,
        component: TelehealthLandingRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.telehealth,
        layout: DashboardLayout,
        component: TelehealthLandingRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.telehealthHands,
        layout: DashboardLayout,
        component: TelehealthHandsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.telehealthTremors,
        layout: DashboardLayout,
        component: TelehealthTremorsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.telehealthHands,
        layout: DashboardLayout,
        component: TelehealthHandsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.telehealthExternalShoulder,
        layout: DashboardLayout,
        component: TelehealthExternalShoulderRotationRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
    {
        path: Paths.demoProducts,
        layout: DashboardLayout,
        component: ProductsRoute,
        protected: true,
        redirect: Paths.userLogin,
    },
];
