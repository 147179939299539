import React from 'react';
import { actions } from '@exerai/react-core';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { AccountPath, Paths } from '@/common/const';
import { useExerFeatureFlags } from '@/common/hooks/ExerFeatureFlags';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useMuiUtils } from '@/common/hooks/MuiUtils';
import { mixpanel } from '@/common/mixpanel';
import { isExerAdmin } from '@/common/utils';
import { AdminIcon } from '@/components/common/Icons/AdminIcons';
import { ComputerGraphIcon } from '@/components/common/Icons/ComputerGraphIcon';
import { DemoPathsIcon, DemoModuleIcon } from '@/components/common/Icons/DemoNavIcons';
import { FeetIcon } from '@/components/common/Icons/FeetIcon';
import { GearIcon } from '@/components/common/Icons/GearIcon';
import { HealthShieldIcon } from '@/components/common/Icons/HealthShieldIcon';
import { HomeIcon } from '@/components/common/Icons/HomeIcon';
import { IndustrialBuildingIcon } from '@/components/common/Icons/IndustrialBuildingIcon';
import { InfoIcon } from '@/components/common/Icons/InfoIcon';
import { PatientsIcon } from '@/components/common/Icons/PatientsIcon';
import { ProgramTemplateLibraryIcon } from '@/components/common/Icons/ProgramTemplateLibraryIcon';
import { ScanIpadIcon } from '@/components/common/Icons/ScanIpadIcon';
import { NavbarCollapseControls } from '@/components/layout/Controls/NavbarCollapseControls';
import { globalFilterState } from '@/recoil/atoms/globalSearchAtom';
import { orgLogoAtomState } from '@/recoil/atoms/orgLogoAtom';
import { SidebarNavItem } from './SidebarNavItem';

export const MiniSidebarContent = () => {
    const { dispatchUser, isHealthUser, isScanOnlyUser, user, isGaitUser, isScanUser } = useExerUser();
    const isAdmin = isExerAdmin(user);
    const navigate = useNavigate();
    const { mediumBreakpoint } = useMuiUtils();
    const location = useLocation();
    const { flags } = useExerFeatureFlags();
    const setGlobalFilters = useSetRecoilState(globalFilterState);
    const resetOrgLogo = useResetRecoilState(orgLogoAtomState);
    const containsUserID = new RegExp(/patients\/[0-9]/).test(location.pathname);

    const handleLogout = async () => {
        try {
            setGlobalFilters({
                locationId: null,
                products: [],
            });
            await Auth.signOut();
            dispatchUser({ type: actions.LOGOUT });
            resetOrgLogo();
            mixpanel.track('Signed Out');
            mixpanel.reset();
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    const handleTrackClick = (e: any, to?: string) => {
        to && navigate(to);
        mixpanel.track(`User clicked on ${e.target.innerText} page from sidebar`, {
            // conditionally remove current url if it contains a user id
            ...(containsUserID && { $current_url: '' }),
        });
    };

    const handleSupportClick = () => {
        window.open('https://hi.exer.ai/knowledge', '_blank')?.focus();
    };

    const handleChatClick = () => {
        window.HubSpotConversations.widget.open();
        const iframe = document.getElementById('hubspot-messages-iframe-container');
        if (iframe) {
            iframe.classList.add('active');
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
                data-cy="nav-sidebar"
            >
                <SidebarNavItem to={Paths.dashboard} title="Home" icon={<HomeIcon />} />
                {!isScanOnlyUser() && (
                    <SidebarNavItem to={Paths.organizationPatients} title="Patients" icon={<PatientsIcon />} />
                )}
                {isScanUser() && <SidebarNavItem to={Paths.scanSessions} title="Scan" icon={<ScanIpadIcon />} />}
                {isGaitUser() && <SidebarNavItem to={Paths.gaitReports} title="Gait" icon={<FeetIcon />} />}
                {isHealthUser() && (
                    <SidebarNavItem
                        to={Paths.billing}
                        title="RTM Billing"
                        onClick={(e) => handleTrackClick(e, Paths.billing)}
                        icon={<HealthShieldIcon />}
                    />
                )}

                {isHealthUser() && (
                    <>
                        <SidebarNavItem
                            to={Paths.programsExerciseLibrary}
                            title="Motion Library"
                            icon={<ComputerGraphIcon />}
                        />
                        <SidebarNavItem
                            to={Paths.programsHEPTemplates}
                            title="Program Templates"
                            icon={<ProgramTemplateLibraryIcon />}
                        />
                        {flags?.paths ? (
                            <SidebarNavItem to={Paths.paths} title="Paths" icon={<DemoPathsIcon />} />
                        ) : (
                            <></>
                        )}
                        {flags?.modules ? (
                            <SidebarNavItem to={Paths.demoProducts} title="Modules" icon={<DemoModuleIcon />} />
                        ) : (
                            <></>
                        )}
                    </>
                )}

                <SidebarNavItem to={Paths.organization} title="Your Organization" icon={<IndustrialBuildingIcon />}>
                    <SidebarNavItem to={Paths.organizationPractitioners} title="Providers" isChild />
                    <SidebarNavItem to={Paths.organizationDetails} title="Organization Details" isChild />
                </SidebarNavItem>
                <SidebarNavItem to={Paths.userAccountInfo} title="Support" icon={<InfoIcon />}>
                    <SidebarNavItem
                        to={null}
                        title="Exer Help Center"
                        onClick={(e) => {
                            handleSupportClick();
                        }}
                        isChild
                    />
                    <SidebarNavItem to={'#hs-chat-open'} title="Chat With Us" onClick={handleChatClick} isChild />
                </SidebarNavItem>
                <SidebarNavItem to={Paths.userAccountInfo} title="Settings" icon={<GearIcon />}>
                    <SidebarNavItem to={Paths.userAccountInfo + AccountPath.Info} title="Your Account" isChild />
                    <SidebarNavItem to={null} title="Logout" onClick={handleLogout} isChild />
                </SidebarNavItem>
            </Box>
            {!mediumBreakpoint && (
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        /* Hack to account for Hubspot chat widget */ paddingBottom: 50,
                    }}
                >
                    <NavbarCollapseControls />
                </Box>
            )}
            <Box>{isAdmin && <SidebarNavItem to={Paths.admin} title="Switch to Admin" icon={<AdminIcon />} />}</Box>
        </>
    );
};
