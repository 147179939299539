import React, { useEffect } from 'react';
import { Assessment, SessionResultDict } from '@exerai/vision-engine-web';
import { PrintRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { BlobProvider, Font } from '@react-pdf/renderer';
import { Maybe } from '@/common/types';
import dmSansBold from '@/fonts/DMSans-Bold.ttf';
import dmSansMedium from '@/fonts/DMSans-Medium.ttf';
import { SessionResultsPDFDocument } from './SessionResultsPDFDocument';
import { SessionParticipant } from './types';
import { DateTime } from 'luxon';

interface SessionResultsPDFActionProps {
    results: SessionResultDict;
    assessment: Assessment;
    client: 'gait' | 'scan';
    participant: SessionParticipant;
    organizationImageUrl: Maybe<string>;
    capturedDate: string;
}
export const SessionResultsPDFAction = (props: SessionResultsPDFActionProps) => {
    const { client, capturedDate } = props;

    useEffect(() => {
        Font.register({
            family: 'DM Sans',
            fonts: [{ src: dmSansMedium }, { src: dmSansBold, fontWeight: 600 }],
        });
    }, []);

    return (
        <>
            <BlobProvider document={<SessionResultsPDFDocument {...props} />}>
                {({ url, loading, error, blob }) => {
                    return (
                        <a href={url || undefined} download={`${client[0].toUpperCase()}${client.slice(1)}_Session_Results_${DateTime.fromISO(capturedDate).toFormat('L-dd-yyyy')}.pdf`}>
                            <IconButton
                                onClick={loading || !url ? undefined : undefined}
                                disabled={loading || !url}
                                edge="end"
                            >
                                <PrintRounded />
                            </IconButton>
                        </a>
                    );
                }}
            </BlobProvider>
        </>
    );
};

export default SessionResultsPDFAction;
