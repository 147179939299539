import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { UserProfile } from '@/common/types';
import { refreshUserProfile } from '@/common/utils';
import { FormTextField, UpdateButton } from '@/components/common/Form/Form';
import { ServerError, SubmitProgress, SubmitProgressWrapper } from '@/components/common/Form/styles';
import { resultIsError } from '@/services/HttpService';
import { AccountInfoFormContainer } from './styles';

interface Props {
    userProfile: UserProfile;
}

interface Data {
    firstName: string;
    lastName: string;
}

export const AccountInfo = (props: Props) => {
    const { userProfile } = props;
    const { httpService } = useHttpContext();
    const { dispatchUser } = useExerUser();
    const [serverError, setServerError] = useState<string | null>();
    const [processing, setProcessing] = useState(false);
    const { successToast } = useToast();

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: {
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            email: userProfile.email,
        },
    });

    const onSubmit = async (data: Data) => {
        setProcessing(true);
        setServerError(null);
        const result = await httpService.updateUserProfile(userProfile.id, {
            userId: userProfile.userId,
            firstName: data.firstName,
            lastName: data.lastName,
        });
        if (resultIsError(result)) {
            setServerError(result.message);
            setProcessing(false);
            return;
        } else {
            await refreshUserProfile(httpService, dispatchUser, result);
            successToast('Account details updated.');
            setProcessing(false);
        }
    };
    return (
        <AccountInfoFormContainer data-cy="account-info-form-container">
            <form noValidate>
                <FormTextField control={control} errors={errors} name="firstName" label="First Name" required={true} />
                <FormTextField control={control} errors={errors} name="lastName" label="Last Name" required={true} />
                <FormTextField
                    control={control}
                    errors={errors}
                    name="email"
                    label="Email"
                    required={false}
                    disabled={true}
                />
                <ServerError>{serverError || '\u00a0'}</ServerError>
                {processing ? (
                    <SubmitProgressWrapper>
                        <SubmitProgress />
                    </SubmitProgressWrapper>
                ) : (
                    <UpdateButton updateFunction={handleSubmit(onSubmit)} />
                )}
            </form>
        </AccountInfoFormContainer>
    );
};
