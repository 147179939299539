import React, { FC } from 'react';
import { Products } from '@/common/const';
import { Maybe } from '@/common/types';
import { SessionOutcomes } from '@/components/Scan/Outcomes/SessionOutcomes';
import { hphOutcomes } from '@/components/Scan/Outcomes/hphOutcomesMock';
import { AssessmentOutcome, ScanSessionOutcomeData } from '@/components/Scan/Outcomes/types/types';
import { SessionOutcomesAction } from '@/components/common/Pose/SessionOutcomesAction';

interface SessionOutcomesActionContainerProps {
    orgId: number;
    scanSessionOutcomeData: Maybe<ScanSessionOutcomeData>;
    sessionIdentifier: string;
}

export const SessionOutcomesActionContainer: FC<SessionOutcomesActionContainerProps> = ({
    scanSessionOutcomeData,
    orgId,
    sessionIdentifier,
}) => {
    const sessionData = scanSessionOutcomeData?.session?.data;
    let outcomes: Maybe<Map<string, AssessmentOutcome>>;

    switch (process.env.ENVIRONMENT) {
        case 'DEVELOPMENT':
        case 'LOCAL':
            outcomes = hphOutcomes;
        case 'STAGING':
            outcomes = hphOutcomes;
            break;
        case 'PRODUCTION':
            switch (orgId) {
                case 40:
                case 8:
                case 55:
                    outcomes = hphOutcomes;
                    break;
                default:
            }
            break;
    }

    return sessionData?.assessmentId && outcomes?.get(sessionData.assessmentId) ? (
        <SessionOutcomesAction product={Products.SCAN}>
            <SessionOutcomes
                scanSessionOutcomeData={scanSessionOutcomeData!}
                sessionIdentifier={sessionIdentifier}
                outcomes={outcomes}
            />
        </SessionOutcomesAction>
    ) : null;
};
