import { ROLES } from './types';

export enum Paths {
    main = '/',
    organization = '/organization/',
    organizationDetails = '/organization/details/',
    organizationPractitioners = '/organization/providers/',
    organizationPatients = '/patients/',
    userLogout = '/user/logout/',
    userLogin = '/user/login/',
    userForgotPassword = '/user/forgot-password/',
    userResetPassword = '/user/reset-password/',
    userCreateAccount = '/user/create/',
    userAccountInfo = '/user/account/',
    userProviderNotFound = '/user/not-found/',
    patients = '/patients/',
    programs = '/programs/',
    programsExerciseLibrary = '/programs/exercise-library/',
    programsHEPTemplates = '/programs/templates/',
    programsHEPTemplateBuilder = '/programs/templates/builder/',
    billing = '/rtm-billing/cycles-by-month/',
    gait = '/gait/',
    gaitReports = '/gait/reports/',
    gaitReconcile = '/gait/reconcile/',
    gaitEventPatients = '/gait/events/patients/',
    scan = '/scan/',
    scanSessions = '/scan/sessions/',
    admin = '/admin/',
    adminOrg = '/admin/organizations/',
    adminUserByUUID = '/admin/users/find-by-uuid',
    adminUserSignUpLink = '/admin/users/signup-link',
    adminInsights = '/admin/insights',
    adminLocations = '/admin/locations/',
    adminScan = '/admin/scan/',
    adminScanSessions = '/admin/scan/sessions/',
    adminScanClientVersions = '/admin/scan/client-versions/',
    adminPROs = '/admin/pros/',
    adminPROForms = '/admin/pros/forms/',
    adminPROResponses = '/admin/pros/responses/',
    adminGait = '/admin/gait/',
    adminGaitSessions = '/admin/gait/sessions',
    adminSettings = '/admin/settings/',
    dashboard = '/home/',
    telehealth = '/telehealth/',
    telehealthHands = '/telehealth/hands/',
    telehealthTremors = '/telehealth/tremors/',
    telehealthExternalShoulder = '/telehealth/external-shoulder-rotation/',
    demoHands = '/demo/hands/',
    paths = '/programs/paths/',
    demoProducts = '/programs/products',
}

export enum HealthPatientPath {
    activity = 'activity',
    rom = 'rom',
    pro = 'pro',
    care = 'care',
}

export const healthPatientPaths = Object.values(HealthPatientPath);

export enum GaitPatientPath {
    activity = 'activity',
    progress = 'progress',
}

export const gaitPatientPaths = Object.values(GaitPatientPath);

export enum AccountPath {
    Info = 'info',
    Password = 'password',
}

export const ProviderRoles = {
    PORTAL_PRACTITIONER: 'Provider',
    PORTAL_PROVIDER_ADMIN: 'Admin',
};

export const UNKNOWN_ROLE_LABEL = 'Unknown';

export const ProviderAndExerRoles = { ...ProviderRoles, ...{ EXER_ADMIN: 'Exer Admin', UNKNOWN: UNKNOWN_ROLE_LABEL } };

export const AllowedPortalRoles: ROLES[] = ['PORTAL_PROVIDER_ADMIN', 'PORTAL_PRACTITIONER', 'EXER_ADMIN'];

export enum Products {
    HEALTH = 'Health',
    GAIT = 'Gait',
    PRO = 'PRO',
    SCAN = 'Scan',
}

export const ProviderOnlyProducts = [Products.SCAN];

export enum GaitSessionVersion {
    GAIT_SA_1 = 'GAIT-SA-1',
    GAIT_SA_2 = 'GAIT-SA-2',
    GAIT_FA_1 = 'GAIT-FA-1',
    GAIT_FA_2 = 'GAIT-FA-2',
    // TODO once we have FA check if table data is different or if we can use v1 = 'GAIT-SA-1' | 'GAIT-FA-1',
}

export enum SideOfBody {
    RIGHT = 'Right',
    LEFT = 'Left',
    N_A = 'N/A',
}

export enum Involvedness {
    INVOLVED = 'Involved',
    UNINVOLVED = 'Uninvolved',
    NA = 'N/A',
}

export enum BodyPart {
    SHOULDER = 'Shoulder',
    HIP = 'Hip',
    KNEE = 'Knee',
    SPINE = 'Spine',
    MULTIPLE = 'Multiple',
}

export enum ExerciseSessionType {
    'HEPSession' = 'HEPSession',
    'SingleSession' = 'SingleSession',
}

export enum RecordType {
    PATIENT = 'Patient',
    PRACTITIONER = 'Provider',
}

export type ExerciseSessionTypes = keyof typeof ExerciseSessionType;

export const DefaultThumbnailUrl = 'https://exer-exercise-media.s3.amazonaws.com/thumbnails/placeholder.jpg';

export const PAGE_SIZE = 10;

export enum PATIENT_STATUS {
    ALL = 'ALL',
    ACTIVE = 'ACTIVE',
    DISCHARGED = 'DISCHARGED',
}

export enum HealthPatientStatus {
    NO_ASSIGNED_HEP = 'No Program Assigned',
    NO_ACCOUNT = 'Invite Sent',
    HAS_ACCOUNT = 'Active',
}
// Exercise Categorization
export enum MetricCode {
    ROM = 'ROM',
    REPS = 'Reps',
    TIME = 'Time',
}

export enum BodyTarget {
    SHOULDER = 'Shoulder',
    HIP_KNEE = 'Hip/Knee',
    TORSO_CORE = 'Torso/Core',
    ELBOW_HAND = 'Elbow/Hand',
    ANKLE_FOOT = 'Ankle/Foot',
    NECK = 'Neck',
    SPINE = 'Spine',
}

export enum ExerciseType {
    MOBILITY = 'Stretching/Mobility',
    ACTIVE_DYNAMIC = 'Active/Dynamic',
    ISOMETRIC = 'Isometric',
}

export enum Equipment {
    THERABAND = 'Theraband',
    WEIGHTS = 'Weights',
    TOWEL = 'Towel',
    CANE = 'Cane',
    CHAIR = 'Chair',
    PHYSIOBALL = 'Physioball',
}

export enum SubjectPosition {
    STANDING = 'Standing',
    PRONE = 'Prone',
    SIDE_LYING = 'Side Lying',
    SUPINE = 'Supine',
    SEATED = 'Seated',
    QUADRUPED = 'Quadruped',
    KNEELING = 'Kneeling',
}

export enum CameraOrientation {
    PORTRAIT = 'Portrait',
    LANDSCAPE = 'Landscape',
}

export enum Sequence {
    SINGLE = 'Single',
    ALTERNATING = 'Alternating',
    BILATERAL = 'Bilateral',
}

export enum IsFavoriteOptions {
    isFavorite = 'Is Favorite',
}

export enum IsAssessmentOptions {
    isAssessment = 'Is Assessment',
}

// End Exercise Categorization

export const PDF_CHUNK_AMOUNT = 18;

export enum RtmCycleStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    ACTIVE_DAYS_INCOMPLETE = 'ACTIVE_DAYS_INCOMPLETE',
    READY_TO_BILL = 'READY_TO_BILL',
    BILLED = 'BILLED',
}

export enum RtmCode {
    PATIENT_SETUP = 98975,
    DEVICE_AND_DATA_TRANSMISSION = 98977,
    PATIENT_INTERACTION = 98980,
    ADDITIONAL_PATIENT_INTERACTION = 98981,
}

export const RtmDescription = {
    98975: 'Exer Initial Onboarding and Education',
    98977: 'Exer Device and Data Transmission',
    98980: 'Care Management',
    98981: 'Additional Care Management',
};

export enum RtmEvent {
    PATIENT_INTERACTION = 'PATIENT_INTERACTION',
    HEP_SESSION = 'HEP_SESSION',
    PRO_RESPONSE = 'PRO_RESPONSE',
    INITIAL_HEP_ASSIGNMENT = 'INITIAL_HEP_ASSIGNMENT',
    SELF_REPORTED_HEP_SESSION = 'SELF_REPORTED_HEP_SESSION',
}

export enum RtmEventDisplay {
    PATIENT_INTERACTION = 'Care Management',
    HEP_SESSION = 'Program Session',
    PRO_RESPONSE = 'PRO Response',
    INITIAL_HEP_ASSIGNMENT = 'Initial Program Assignment',
}

export const monthsIndexDict = {
    jan: 1,
    feb: 2,
    mar: 3,
    apr: 4,
    may: 5,
    jun: 6,
    jul: 7,
    aug: 8,
    sep: 9,
    oct: 10,
    nov: 11,
    dec: 12,
};

export const CARD_PAGE_SIZE = 5;

// Provider Dashboard Actions
export enum DashboardActions {
    NO_ACCOUNT = 'NO_ACCOUNT',
    NO_HEP = 'NO_HEP',
    NO_RECENT_SESSION = 'NO_RECENT_SESSION',
    READY_TO_BILL = 'READY_TO_BILL',
}

export enum DashboardActionStatus {
    INVITE_SENT = 'Invite Sent',
    NOT_ASSIGNED = 'Not Assigned',
    READY_TO_BILL = 'Ready to Bill',
}

export enum SortBy {
    NAME = 'NAME',
    LAST_SESSION = 'LAST_SESSION',
    LAST_ACTIVITY = 'LAST_ACTIVITY',
    LOCATION = 'LOCATION',
    STATUS = 'STATUS',
    EMAIL = 'EMAIL',
}

export enum PROAnswerType {
    CHOICE = 'CHOICE',
    BOOLEAN = 'BOOLEAN',
    TEXT = 'TEXT',
    UNKNOWN = 'UNKNOWN',
}

export enum DischargeOrPocEnum {
    DISCHARGE = 'DISCHARGE',
    POC = 'POC',
}

export enum SelfReportedEnum {
    FULL = 'FULL',
    PARTIAL = 'PARTIAL',
    NONE = 'NONE',
}

export enum ScanResultsSchema {
    SCAN1 = 'SCAN-1',
}

// https://getyarn.io/yarn-clip/385ca108-c583-43a7-8e2d-e5b42670aa7f
export const AssessmentDict = {
    'AST-0001': 'Functional Reach',
    'AST-0008': 'Sit to Stand Assessment ROM (Side Facing)',
    'AST-0009': 'TUG Assessment',
    'AST-0010': 'TUG Assessment (side facing)',
    'AST-0011': 'Sit to Stand Assessment (Side Facing)',
    'AST-0012': 'Sit to Stand Assessment (Front Facing)',
    'AST-0017': 'Posture Assessment',
    'AST-0018': 'Gait Speed (Front Facing)',
    'AST-0019': '30 Second Sit to Stand',
    'AST-0020': 'Single Leg Balance Assessment ',
    'AST-0021': 'Trunk Flexion',
    'HK-0058': 'Knee Extension/Flexion',
    'AST-9999': 'Open Assessment',
};

export const MAJOR_MINOR_PATCH_REG_EXP = new RegExp(/^\d+\.\d+\.\d+$/);

export const GAIT_TEST_UIDS = ['test', '9999'];

export enum Comparator {
    lt = 'lt',
    gt = 'gt',
    lte = 'lte',
    gte = 'gte',
    e = 'e',
}

export enum ComparatorSymbolByString {
    lt = '<',
    gt = '>',
    lte = '<=',
    gte = '>=',
    e = '=',
}
