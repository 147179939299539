import { Maybe, Nullable } from '@exerai/vision-engine-web/dist/src/common/types';
import { Comparator } from '@/common/const';
import { ScanSessionJSON } from '@/common/types';
import { CriteriaCategory, DemographicsCategory } from './CriteriaCategory.enum';
import { CriteriaUnit } from './CriteriaUnit.enum';
import { FactorCategory } from './FactorCategory.enum';
import { OutcomeCategory } from './OutcomeCategory.enum';
import { Sex } from './Sex.enum';

export interface ScanSessionOutcomeData {
    createdDate: string;
    practitionerName: string;
    assessmentName: string;
    session?: ScanSessionJSON;
    participantDetails: {
        uid: Nullable<string>;
        archivedDate: Nullable<string>;
    };
}

export interface OutcomeFactor {
    category: FactorCategory;
    description: Nullable<string>;
    criteria: FactorCriteria[];
}

export type FactorResult = Omit<OutcomeFactor, 'criteria'>;

export type FactorCriteriaValue = number | Sex;
interface FactorCriteriaCondition {
    comparator: Comparator;
    value: FactorCriteriaValue;
}

export interface FactorCriteria {
    type: CriteriaCategory;
    units: Nullable<CriteriaUnit>;
    conditions: FactorCriteriaCondition[];
}

export interface AssessmentOutcome {
    type: OutcomeCategory;
    demographicsRequired: Nullable<CriteriaCategory[]>;
    factors: OutcomeFactor[];
}

export interface ParticipantDemographics {
    age: Maybe<{ value: number; type: DemographicsCategory.AGE }>;
    sex: Maybe<{ value: Sex; type: DemographicsCategory.SEX }>;
}

export interface OutcomeBoxData {
    factorResultCategory: Maybe<FactorCategory>;
    name: string;
    value: string;
    units: string;
    label: string;
}

export const UNSPECIFIED = 'UNSPECIFIED';

export type MaybeSex = Sex | typeof UNSPECIFIED;

export type OutcomeFactorTableData = Map<
    MaybeSex,
    {
        rows: OutcomeFactorTableRowData[];
    }
>;

export interface OutcomeFactorTableRowData {
    ageRange: string;
    values: { value: string; unit: Nullable<string> }[];
    isResultRow: boolean;
    factorColor?: string;
}
