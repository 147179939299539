import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useToast } from '@/common/hooks/useToast';
import { FormPasswordField, UpdateButton } from '@/components/common/Form/Form';
import { ServerError, SubmitProgress, SubmitProgressWrapper } from '@/components/common/Form/styles';
import { AccountInfoFormContainer } from './styles';

interface Data {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

export const ChangePassword = () => {
    const { successToast } = useToast();
    const [serverError, setServerError] = useState<string | null>();
    const [processing, setProcessing] = useState(false);

    const schema = yup.object().shape({
        oldPassword: yup.string().required('Old password is required'),
        newPassword: yup.string().required('New password is required'),
        confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: { oldPassword: '', newPassword: '', confirmNewPassword: '' },
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: Data) => {
        setProcessing(true);
        setServerError(null);
        try {
            const currentUser = await Auth.currentUserPoolUser();
            const result = await Auth.changePassword(currentUser, data.oldPassword, data.newPassword);
            if (result !== 'SUCCESS') {
                setServerError('Could not update password.');
            } else {
                reset(
                    {
                        oldPassword: '',
                        newPassword: '',
                        confirmNewPassword: '',
                    },
                    {
                        keepValues: false,
                    },
                );
            }
        } catch (error) {
            setServerError(error.message);
            setProcessing(false);
            return;
        }
        successToast('Password updated.');
        setProcessing(false);
    };
    return (
        <AccountInfoFormContainer>
            <form noValidate>
                <FormPasswordField
                    control={control}
                    errors={errors}
                    name="oldPassword"
                    label="Verify Existing Password"
                    required={true}
                />
                <FormPasswordField
                    control={control}
                    errors={errors}
                    name="newPassword"
                    label="New Password"
                    required={true}
                />
                <FormPasswordField
                    control={control}
                    errors={errors}
                    name="confirmNewPassword"
                    label="Confirm New Password"
                    required={true}
                />
                <ServerError>{serverError || '\u00a0'}</ServerError>
                {processing ? (
                    <SubmitProgressWrapper>
                        <SubmitProgress />
                    </SubmitProgressWrapper>
                ) : (
                    <UpdateButton updateFunction={handleSubmit(onSubmit)} />
                )}
            </form>
        </AccountInfoFormContainer>
    );
};
