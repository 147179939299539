import React, { Dispatch, SetStateAction, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { StatesRegions } from '@/common/StatesRegions';
import { Products } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { CreateOrganizationDto, Organization } from '@/common/types';
import { getStringEnumKeyByValue } from '@/common/utils';
import { FormAutoCompleteField, FormTextField, SubmitCancelButtons } from '@/components/common/Form/Form';
import { FormFieldContainer, FormHr, ServerError } from '@/components/common/Form/styles';
import { resultIsError } from '@/services/HttpService';
import { LocationStateSelectWrapper } from '../Locations/LocationForm/styles';
import { OrgLogo } from '../OrganizationLogo/OrganizationLogo';

interface AddOrganizationFormProps {
    cancel: () => void;
    currentStep: number;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    org: Organization | undefined;
    setOrg: Dispatch<SetStateAction<Organization>>;
}

type CreateOrganizationFormData = {
    orgName: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    orgTimezone: string;
    products: string[];
    name: string;
    locationTimezone: string;
    address1: string;
    address2: string;
    city: string;
    stateCode: string;
    zipCode: string;
    countryCode: string;
    keyword: string;
};

const shape = {
    orgName: yup.string().required('Organization name is required'),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().required('Email is required').email('Email must be valid'),
    role: yup.string().required('Role must be valid'),
    orgTimezone: yup.string().required('Organization Timezone is required'),
    products: yup.array(yup.string().required()).min(1),
    name: yup.string().required('Name is required'),
    locationTimezone: yup.string().required('Location Timezone is required'),
    address1: yup.string().nullable(),
    address2: yup.string().nullable(),
    city: yup.string().nullable(),
    stateCode: yup.string().nullable(),
    zipCode: yup.string().nullable(),
    countyCode: yup.string().nullable(),
    keyword: yup.string().nullable(),
};

export const AddOrganizationForm = (props: AddOrganizationFormProps) => {
    const { cancel, currentStep, setCurrentStep, setOrg, org } = props;
    const { httpService } = useHttpContext();
    const [serverError, setServerError] = useState<string | null>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { successToast, errorToast } = useToast();
    const [orgLogoUrl, setOrgLogoUrl] = useState<string | undefined>(undefined);
    const [newOrgLogo, setNewOrgLogo] = useState<File | null>(null);
    const [shouldDeleteOrgLogo, setShouldDeleteOrgLogo] = useState(false);

    const defaultValues = {
        orgName: '',
        firstName: '',
        lastName: '',
        email: '',
        role: 'PORTAL_PROVIDER_ADMIN',
        orgTimezone: 'America/Denver',
        products: [],
        name: '',
        locationTimezone: 'America/Denver',
        address1: '',
        address2: '',
        city: '',
        stateCode: '',
        zipCode: '',
        countryCode: '',
        keyword: '',
    };

    const schema = yup.object().shape(shape);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const transformData = async (dto: CreateOrganizationFormData): Promise<CreateOrganizationDto> => {
        return {
            orgName: dto.orgName,
            practitioner: {
                firstName: dto.firstName,
                lastName: dto.lastName,
                email: dto.email || null,
                role: dto.role,
            },
            timezone: dto.orgTimezone,
            products: dto.products,
            location: {
                name: dto.name,
                timezone: dto.locationTimezone,
                address1: dto.address1 || null,
                address2: dto.address2 || null,
                city: dto.city || null,
                stateCode: dto.stateCode || null,
                countryCode: dto.countryCode || null,
                zipCode: dto.zipCode || null,
                keyword: dto.keyword || null,
            },
        };
    };

    const addOrganization = async (data: CreateOrganizationFormData): Promise<Organization | undefined> => {
        setServerError(null);
        setIsSubmitting(true);
        const organizationDto = await transformData(data);
        const result = await httpService.createOrganizationAdmin(organizationDto);
        setIsSubmitting(false);
        if (resultIsError(result)) {
            setServerError(result.message);
        } else {
            successToast(`Organization Added.`);
            setOrg(result);
            return result;
        }
    };

    const handleCancelClick = () => {
        setShouldDeleteOrgLogo(false);
        setNewOrgLogo(null);
        window.location.reload();
    };

    const handleOrgLogoSubmit = async () => {
        if (!org) return;

        const res = await httpService.uploadAdminOrgLogo(org.id, newOrgLogo);

        if (resultIsError(res)) {
            if (res.message) {
                errorToast(`Failed to upload logo - ${res.message}`);
            } else {
                errorToast('Something went wrong uploading your image, please try again.');
            }
        } else {
            window.location.reload();
        }
    };

    const save = async (data: CreateOrganizationFormData) => {
        const result = await addOrganization(data);
        if (result) {
            setCurrentStep(2);
        }
    };

    return (
        <>
            {currentStep === 1 ? (
                <form noValidate data-cy="add-organization-form">
                    <p></p>
                    <FormTextField
                        control={control}
                        errors={errors}
                        name="orgName"
                        label="Organization Name"
                        required={true}
                    />
                    <FormFieldContainer>
                        <h4>Products</h4>
                        <Controller
                            name="products"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => {
                                return (
                                    <FormControl variant="outlined">
                                        <Select multiple {...field}>
                                            {Object.values([
                                                Products.HEALTH,
                                                Products.GAIT,
                                                Products.PRO,
                                                Products.SCAN,
                                            ]).map((option: Products) => {
                                                return (
                                                    <MenuItem
                                                        key={option}
                                                        value={getStringEnumKeyByValue(Products, option)}
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                );
                            }}
                        />
                    </FormFieldContainer>
                    <FormHr />
                    <FormFieldContainer>
                        <h4>Provider Admin</h4>
                        <FormTextField
                            control={control}
                            errors={errors}
                            name="firstName"
                            label="First Name"
                            required={true}
                        />
                        <FormTextField
                            control={control}
                            errors={errors}
                            name="lastName"
                            label="Last Name"
                            required={true}
                        />
                        <FormTextField control={control} errors={errors} name="email" label="Email" required={true} />
                    </FormFieldContainer>
                    <FormHr />
                    <FormFieldContainer>
                        <h4>Organization Timezone</h4>
                        <FormAutoCompleteField
                            control={control}
                            errors={errors}
                            name="orgTimezone"
                            label="Organization Timezone"
                            options={Intl.supportedValuesOf('timeZone')}
                            optionLabel={(timezone: string) => timezone}
                            clearIcon={false}
                        />
                    </FormFieldContainer>
                    <FormHr />
                    <FormFieldContainer>
                        <h4>First Location</h4>
                        <FormTextField
                            name="name"
                            label="Location Name"
                            control={control}
                            errors={errors}
                            required={true}
                        />
                        <FormAutoCompleteField
                            control={control}
                            errors={errors}
                            name="locationTimezone"
                            label="Location Timezone"
                            options={Intl.supportedValuesOf('timeZone')}
                            optionLabel={(timezone: string) => timezone}
                            clearIcon={false}
                        />
                        <FormTextField
                            name="address1"
                            label="Address Line 1"
                            control={control}
                            errors={errors}
                            required={false}
                        />
                        <FormTextField name="address2" label="Address Line 2" control={control} errors={errors} />
                        <FormTextField name="city" label="City" control={control} errors={errors} />
                        <FormFieldContainer>
                            <LocationStateSelectWrapper>
                                <Controller
                                    name="stateCode"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <FormControl variant="outlined">
                                                <InputLabel shrink htmlFor="stateCode">
                                                    State
                                                </InputLabel>
                                                <Select label="State" {...field}>
                                                    <MenuItem>None</MenuItem>
                                                    {Object.keys(StatesRegions).map((key) => {
                                                        return (
                                                            <MenuItem key={key} value={key}>
                                                                {StatesRegions[key]}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                <p> </p>
                                            </FormControl>
                                        );
                                    }}
                                />
                            </LocationStateSelectWrapper>
                        </FormFieldContainer>
                        <FormTextField name="zipCode" label="Zip Code" control={control} errors={errors} />
                        <FormTextField name="countryCode" label="Country" control={control} errors={errors} />
                        <FormTextField name="keyword" label="keyword" control={control} errors={errors} />
                    </FormFieldContainer>

                    <ServerError>{serverError || '\u00a0'}</ServerError>
                    <SubmitCancelButtons
                        submitFunction={handleSubmit(save)}
                        cancelFunction={cancel}
                        submitDisabled={isSubmitting}
                    />
                </form>
            ) : (
                <>
                    <OrgLogo
                        orgLogoUrl={orgLogoUrl}
                        setNewOrgLogo={setNewOrgLogo}
                        setShouldDeleteOrgLogo={setShouldDeleteOrgLogo}
                    />
                    <SubmitCancelButtons
                        submitFunction={handleOrgLogoSubmit}
                        cancelFunction={handleCancelClick}
                        submitDisabled={isSubmitting}
                    />
                </>
            )}
        </>
    );
};
