import React from 'react';
import { actions } from '@exerai/react-core';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import { useResetRecoilState } from 'recoil';
import { Paths } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { LogoutIcon, OrgIcon, LocationsIcon, AdminBarChartIcon } from '@/components/common/Icons/AdminIcons';
import { AdminIcon } from '@/components/common/Icons/AdminIcons';
import { CommentIcon } from '@/components/common/Icons/CommentIcon';
import { FeetIcon } from '@/components/common/Icons/FeetIcon';
import { GearIcon } from '@/components/common/Icons/GearIcon';
import { PatientReportedOutcomesIcon } from '@/components/common/Icons/PatientReportedOutcomesIcon';
import { PatientsIcon } from '@/components/common/Icons/PatientsIcon';
import { ScanIpadIcon } from '@/components/common/Icons/ScanIpadIcon';
import { ScanSessionsIcon } from '@/components/common/Icons/ScanSessionsIcon';
import { VersionIcon } from '@/components/common/Icons/VersionIcon';
import { NavbarCollapseControls } from '@/components/layout/Controls/NavbarCollapseControls';
import { orgLogoAtomState } from '@/recoil/atoms/orgLogoAtom';
import { SidebarNavItem } from './SidebarNavItem';
import { SidebarContentWrapper } from './styles';

export const AdminSidebarContent = () => {
    const { dispatchUser } = useExerUser();
    const resetOrgLogo = useResetRecoilState(orgLogoAtomState);
    resetOrgLogo();

    const handleLogout = async () => {
        try {
            await Auth.signOut();
            dispatchUser({ type: actions.LOGOUT });
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    return (
        <SidebarContentWrapper data-cy="nav-sidebar">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <SidebarNavItem
                    to={Paths.adminOrg}
                    title="Organizations"
                    icon={<OrgIcon width={20} height={20} fill="currentcolor" />}
                />
                <SidebarNavItem
                    to={Paths.adminLocations}
                    title="Locations"
                    icon={<LocationsIcon width={20} height={20} fill="currentcolor" />}
                />
                <SidebarNavItem
                    to={Paths.adminScan}
                    title="Scan"
                    icon={<ScanIpadIcon width={20} height={20} fill="currentcolor" />}
                >
                    <SidebarNavItem
                        to={Paths.adminScanClientVersions}
                        title="Client Versions"
                        icon={<VersionIcon width={20} height={20} fill="currentcolor" />}
                        isChild
                    />
                    <SidebarNavItem
                        to={Paths.adminScanSessions}
                        title="Sessions"
                        icon={<ScanSessionsIcon width={20} height={20} fill="currentcolor" />}
                        isChild
                    />
                </SidebarNavItem>
                <SidebarNavItem
                    to={Paths.adminGait}
                    title="Gait"
                    icon={<FeetIcon width={20} height={20} fill="currentcolor" />}
                >
                    <SidebarNavItem
                        to={Paths.adminGaitSessions}
                        title="Sessions"
                        icon={<ScanSessionsIcon width={20} height={20} fill="currentcolor" />}
                        isChild
                    />
                </SidebarNavItem>
                <SidebarNavItem
                    to={Paths.adminPROs}
                    title="PROs"
                    icon={<PatientReportedOutcomesIcon width={20} height={20} fill="currentcolor" />}
                >
                    <SidebarNavItem
                        to={Paths.adminPROForms}
                        title="PRO Forms"
                        icon={<OrgIcon width={20} height={20} fill="currentcolor" />}
                        isChild
                    />
                    <SidebarNavItem
                        to={Paths.adminPROResponses}
                        title="PRO Responses"
                        icon={<CommentIcon width={20} height={20} fill="currentcolor" />}
                        isChild
                    />
                </SidebarNavItem>
                <SidebarNavItem
                    to={Paths.adminUserByUUID}
                    title="Users"
                    icon={<PatientsIcon width={20} height={20} fill="currentcolor" />}
                >
                    <SidebarNavItem to={Paths.adminUserSignUpLink} title="Get Provider Signup Link" isChild />
                    <SidebarNavItem to={Paths.adminUserByUUID} title="Get User By UUID" isChild />
                </SidebarNavItem>
                <SidebarNavItem
                    to={Paths.adminInsights}
                    title="Insights"
                    icon={<AdminBarChartIcon width={20} height={20} fill="currentcolor" />}
                />
                <SidebarNavItem
                    to={Paths.adminSettings}
                    title="Admin Settings"
                    icon={<GearIcon width={20} height={20} fill="currentcolor" />}
                />
                <SidebarNavItem
                    to={null}
                    title="Logout"
                    onClick={handleLogout}
                    icon={<LogoutIcon width={20} height={20} fill="currentcolor" />}
                />
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <NavbarCollapseControls />
            </Box>
            <Box>
                <SidebarNavItem to={Paths.main} title="Switch to Portal" icon={<AdminIcon />} />
            </Box>
        </SidebarContentWrapper>
    );
};
