import { Comparator } from '@/common/const';
import { CriteriaCategory } from './types/CriteriaCategory.enum';
import { CriteriaUnit } from './types/CriteriaUnit.enum';
import { FactorCategory } from './types/FactorCategory.enum';
import { OutcomeCategory } from './types/OutcomeCategory.enum';
import { Sex } from './types/Sex.enum';
import { AssessmentOutcome } from './types/types';

export const hphOutcomes: Map<string, AssessmentOutcome> = new Map([
    [
        'AST-0018',
        {
            type: OutcomeCategory.FALL,
            demographicsRequired: null,
            factors: [
                {
                    category: FactorCategory.LOW,
                    description: '0.8 m/s or faster: Low Risk',
                    criteria: [
                        {
                            type: CriteriaCategory.SPEED,
                            units: CriteriaUnit.METERS_SECOND,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 0.8,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: 'Slower than 0.8 m/s: High Risk',
                    criteria: [
                        {
                            type: CriteriaCategory.SPEED,
                            units: CriteriaUnit.METERS_SECOND,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 0.8,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    [
        'AST-0020',
        {
            type: OutcomeCategory.FALL,
            demographicsRequired: null,
            factors: [
                {
                    category: FactorCategory.LOW,
                    description: 'Greater than 15s: Low Risk',
                    criteria: [
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 15,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: 'Less than 15s: High Risk',
                    criteria: [
                        {
                            type: CriteriaCategory.SPEED,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 15,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    [
        'AST-0009',
        {
            type: OutcomeCategory.FALL,
            demographicsRequired: null,
            factors: [
                {
                    category: FactorCategory.LOW,
                    description: 'Less than 12s: Low Risk',
                    criteria: [
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 12,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: 'Greater than 12s: High Risk',
                    criteria: [
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 12,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    [
        'AST-0010',
        {
            type: OutcomeCategory.FALL,
            demographicsRequired: null,
            factors: [
                {
                    category: FactorCategory.LOW,
                    description: 'Less than 12s: Low Risk',
                    criteria: [
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 12,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: 'Greater than 12s: High Risk',
                    criteria: [
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 12,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    [
        'AST-0001',
        {
            type: OutcomeCategory.FALL,
            demographicsRequired: null,
            factors: [
                {
                    category: FactorCategory.LOW,
                    description: 'Greater than 10": Low Risk Factor',
                    criteria: [
                        {
                            type: CriteriaCategory.DISTANCE,
                            units: CriteriaUnit.DISTANCE_INCHES,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 10,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.MEDIUM,
                    description: '6" - 10": 2X Risk Factor',
                    criteria: [
                        {
                            type: CriteriaCategory.DISTANCE,
                            units: CriteriaUnit.DISTANCE_INCHES,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 6,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 10,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: '6" or less: 4X Risk Factor',
                    criteria: [
                        {
                            type: CriteriaCategory.DISTANCE,
                            units: CriteriaUnit.DISTANCE_INCHES,
                            conditions: [
                                {
                                    comparator: Comparator.lte,
                                    value: 6,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    [
        'AST-0011',
        {
            type: OutcomeCategory.FALL,
            demographicsRequired: [CriteriaCategory.SEX, CriteriaCategory.AGE],
            factors: [
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 60,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 69,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 8.4,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 60,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 69,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 12.7,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 70,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 79,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 11.6,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 70,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 79,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 13,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 80,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 89,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 16.7,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 80,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 89,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 17.2,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 90,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 19.5,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 90,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 22.9,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    [
        'AST-0012',
        {
            type: OutcomeCategory.FALL,
            demographicsRequired: [CriteriaCategory.SEX, CriteriaCategory.AGE],
            factors: [
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 60,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 69,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 8.4,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 60,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 69,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 12.7,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 70,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 79,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 11.6,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 70,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 79,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 13,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 80,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 89,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 16.7,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 80,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 89,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 17.2,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 90,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 19.5,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 90,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.TIME,
                            units: CriteriaUnit.SECONDS,
                            conditions: [
                                {
                                    comparator: Comparator.gt,
                                    value: 22.9,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    [
        'AST-0019',
        {
            type: OutcomeCategory.FALL,
            demographicsRequired: [CriteriaCategory.SEX, CriteriaCategory.AGE],
            factors: [
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 60,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 64,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 14,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 60,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 64,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 12,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 65,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 69,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 12,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 65,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 69,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 11,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 70,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 74,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 12,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 70,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 74,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 1,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 75,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 79,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 11,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 75,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 79,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 10,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 80,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 84,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 10,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 80,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 84,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 9,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 85,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 89,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 8,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 85,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 89,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 8,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.MALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 90,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 94,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 7,
                                },
                            ],
                        },
                    ],
                },
                {
                    category: FactorCategory.HIGH,
                    description: null,
                    criteria: [
                        {
                            type: CriteriaCategory.SEX,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.e,
                                    value: Sex.FEMALE,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.AGE,
                            units: CriteriaUnit.YEARS,
                            conditions: [
                                {
                                    comparator: Comparator.gte,
                                    value: 90,
                                },
                                {
                                    comparator: Comparator.lte,
                                    value: 94,
                                },
                            ],
                        },
                        {
                            type: CriteriaCategory.REPS,
                            units: null,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 4,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    [
        'AST-DUMB-TEST',
        {
            type: OutcomeCategory.FALL,
            demographicsRequired: null,
            factors: [
                {
                    category: FactorCategory.HIGH,
                    description: 'Slower than 10 m/s: High Risk',
                    criteria: [
                        {
                            type: CriteriaCategory.SPEED,
                            units: CriteriaUnit.METERS_SECOND,
                            conditions: [
                                {
                                    comparator: Comparator.lt,
                                    value: 10,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
]);
