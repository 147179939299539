import React, { useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import { actions } from '@exerai/react-core';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useToast } from '@/common/hooks/useToast';
import { mixpanel } from '@/common/mixpanel';
import { isExerAdmin } from '@/common/utils';
import { orgLogoAtomState } from '@/recoil/atoms/orgLogoAtom';
import { MiniDrawer } from '../Sidebar/MiniSidebar';
import { DashboardInnerContent } from './styles';

interface Props {
    children: JSX.Element | JSX.Element[];
}

export const DashboardLayout = (props: Props) => {
    const { children } = props;
    const { user, dispatchUser } = useExerUser();
    const { errorToast } = useToast();
    const setOrgLogoState = useSetRecoilState(orgLogoAtomState);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.groups) {
            if (Array.length === 0) {
                (async () => {
                    try {
                        await Auth.signOut();
                        dispatchUser({ type: actions.LOGOUT });
                        mixpanel.track('Signed Out');
                        mixpanel.reset();
                        errorToast('That account does not have access to the portal.');
                    } catch (e) {
                        Sentry.captureException(`user groups ${e}`);
                    }
                })();
            }
        }
    }, [user]);

    useEffect(() => {
        if (location.pathname?.split('/')?.[1] === 'admin' && !isExerAdmin(user)) {
            navigate('/');
        }
    }, [location, user, navigate]);

    useEffect(() => {
        if (user.organizationLogoUrl) {
            setOrgLogoState(user.organizationLogoUrl);
        }
    }, [user.organizationLogoUrl]);

    return (
        <MiniDrawer>
            <DashboardInnerContent className="dashboard-inner-content">{children}</DashboardInnerContent>
            <Helmet
                style={[
                    {
                        cssText: `
                    div#hubspot-messages-iframe-container {
                        display: none !important;
                    }
                    div#hubspot-messages-iframe-container.active {
                        display: initial !important;
                    }
                `,
                    },
                ]}
            />
        </MiniDrawer>
    );
};
